import { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useNavigate, useLocation } from "react-router-dom";
import "./Navbar.css";
import { productData } from "../data/productData";

function NavBar() {
  const [showAnimated2, setShowAnimated2] = useState(false);
  const [show, setShow] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const pathLikeRoute = (route) => {
    if (location.pathname.includes(route)) {
      return true;
    }
  };

  const pathMatchRoute = (route) => {
    if (route === location.pathname) {
      return true;
    }
  };

  return (
    <>
      <Navbar
        bg="light"
        expand="lg"
        className="sticky-top"
        style={{
          boxShadow: "0px 3px 8px -6px #000000",
        }}
      >
        <Container className="d-flex justify-content-between">
          <div
            className="navbar-brand-cursor-pointer"
            onClick={() => navigate("/")}
          >
            <img
              src={process.env.PUBLIC_URL + "/images/KPN-LOGO-NO-WORD.png"}
              height="55"
              width="55"
              alt="aastar logo"
              className="h-100"
            />
            <span>
              <strong style={{ color: "#ab2f2b" }}>GREEN RESOURCES PTE LTD</strong>
            </span>
          </div>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            type="button"
            className="second-button"
            data-target="#basic-navbar-nav"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setShowAnimated2(!showAnimated2)}
          >
            <div className={`animated-icon2 ${showAnimated2 && "open"}`}>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </Navbar.Toggle>

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link
                className={
                  pathMatchRoute("/")
                    ? "active-nav-link active-underline "
                    : null
                }
                onClick={() => navigate("/")}
              >
                <strong>Home</strong>
              </Nav.Link>
              <Nav.Link
                className={
                  pathMatchRoute("/about")
                    ? "active-nav-link active-underline "
                    : null
                }
                onClick={() => navigate("/about")}
              >
                <strong>About</strong>
              </Nav.Link>
              
              <Nav.Link
                className={
                  pathMatchRoute("/products/biofuel")
                    ? "active-nav-link active-underline "
                    : null
                }
                onClick={() => navigate("/products/biofuel")}
              >
                <strong>Products</strong>
              </Nav.Link>
              <Nav.Link
                className={
                  pathMatchRoute("/contact-us")
                    ? "active-nav-link active-underline "
                    : null
                }
                onClick={() => navigate("/contact-us")}
              >
                <strong>Contact</strong>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavBar;

import React from "react";
import GoogleMapReact from "google-map-react";
import LocationPin from "./LocationPin";

const Map = ({ location, zoomLevel }) => {
  return (
    <iframe 
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15955.270714583394!2d103.8496597!3d1.2832446!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da19973f65abe9%3A0x28bdf77482eef851!2sGreen%20Resources%20Pte%20Ltd!5e0!3m2!1sen!2ssg!4v1693188301399!5m2!1sen!2ssg"
      width="80%" 
      height="300" 
      style={{border: "0"}} 
      allowFullScreen="" 
      loading="lazy" 
      referrerPolicy="no-referrer-when-downgrade">
    </iframe>
  );
};

export default Map;

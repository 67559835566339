export const productData = [
  {
    navLinkName: "Biofuel",
    productCategory: "Biofuel",
    description: [
      <div>
        <p>All our products, including POME, PME, and UCO, bear the prestigious ISCC certification, with the added distinction of POME being INS certified. Green Resources proudly offers a seamless integrated value chain that encompasses origination, precise logistical arrangements, secure storage, and efficient transportation to our valued clients across Europe, U.S and Asia. Our proven track record in exporting to these regions underscores our unwavering commitment to reliability and uncompromising quality.</p>
        <p>Strategically positioned storage tanks located in Sumatra and throughout Kalimantan, combined with a well-connected logistics network within Indonesia, form the backbone of Green Resources' reputation for excellence. These strategic assets are further bolstered by our private jetty position in Lubuk Gaung to ensure the efficient management of our export programs.</p>
      </div>
    ],
    route: "biofuel",
    picture_url: "/images/biodieselHERO.jpg",
    subCategory: [
      {
        name: "Palm Oil Mill Effluent (POME)",
        pictures: [
          // "pome32",
          "pome(1)32",
          // "pome(2)32",
          "pome(3)32",
          // "pome(4)32",
          // "pome(5)32",
          // "pome(6)32",
          // "pome(7)32",
          "pome(8)32",
          "pome(10)32",
          "pome(12)32",
          "pome(13)32",
          "pome(14)32",
        ],
        videos: [
          {
            src: "/videos/pome-video.mp4",
            desc: "Worker working on POME field",
            poster: "/images/pome-video-poster.jpg",
          },
        ],
        description: [
          "At Green Resources, our commitment to sustainability extends to every stage of the palm oil production process. We operate POME collection plants within our own CPO mills and have cultivated enduring partnerships with local millers to consolidate POME cargoes in our storage tanks. With dedicated treatment facilities situated on-site, Green Resources consistently delivers one of the highest-quality POME solutions available in the market. Our comprehensive approach ensures the responsible management of POME, reducing waste and environmental impact.",
        ],
      },
      {
        name: "Used Cooking Oil (UCO)",
        description: [
          "Green Resources takes pride in its role as a key player in the sustainable recycling of Used Cooking Oil (UCO). Our extensive storage tank facilities in Jakarta serve as collection points for UCO, which we meticulously process and refine. Our commitment to UCO recycling aligns with our broader mission to foster a greener, more sustainable world.",
        ],
        products: [],
        pictures: [],
      },
      {
        name: "Palm Kernel Shell/Expeller",
        description: [
          "Palm Kernel Shell (PKS) and Palm Kernel Expeller (PKE) are versatile by-products of the palm oil industry, and Green Resources maximizes their potential in various environmentally responsible ways. PKS, the hard endocarp of palm fruit, serves as a renewable and biodegradable energy source, suitable for power generation and diverse industrial applications. PKE, on the other hand, is a nutrient-rich material derived from palm kernel crushing. It finds utility as livestock feed, enhancing the nutrition of animals, or as a soil amendment to improve soil quality. At Green Resources, we recognize the inherent value of these by-products and strive to harness their potential for the benefit of both industry and the environment.",
        ],
        products: [],
        pictures: [],
      },
      {
        name: "Palm Methyl Ester (PME)",
        products: [],
        description: [
          "Our dedication to sustainable biofuels production comes to life at Green Resources, where our biofuel plant is strategically located adjacent to our palm oil refinery. This proximity allows us to manufacture Palm Methyl Ester (PME) directly from the RBD PALM OIL / RBD PALM STEARIN components that remain after the production of RBD PALM OLEIN. Furthermore, our operational flexibility enables us to efficiently adapt our production processes in response to market dynamics, seizing opportunities as they arise. Green Resources' facility in West & East Kalimantan is poised to exhibit exceptional capabilities, with an annual production capacity of up to 1.5 million tonnes, contributing significantly to the biofuel landscape in the region.",
        ],
      },
    ],
  },
];

export const productSpecificationsData = [
  {
    name: "PORAM",
    details: [
      {
        product: "RBD Palm Olein",
        item: [
          "FFA (as palmitic)",
          "M & I",
          "Iodine Value (Wijs)",
          "M.Pt (AOCS Cc 3-25)",
          "Colour (5.25″ Lovibond cell)",
        ],
        quality_guide_line: [
          "5.00% max",
          "0.5% max",
          "56 min",
          "24 Deg C max",
          "3 Red max",
        ],
      },
      {
        product: "Crude Palm Oil",
        item: ["FFA (as palmitic)", "M & I"],
        quality_guide_line: ["5.00% max", "0.50% max"],
      },
      {
        product: "RBD Palm Oil",
        item: [
          "FFA (as palmitic)",
          "M & I",
          "Iodine Value (Wijs)",
          "M.Pt (AOCS Cc 3-25)",
          "Colour (5.25″ Lovibond cell)",
        ],
        quality_guide_line: [
          "0.10% max",
          "0.10% max",
          "50 - 55",
          "33 - 39 Deg C ",
          "3 Red max",
        ],
      },
      {
        product: "RBD Palm Stearin",
        item: [
          "FFA (as palmitic)",
          "M & I",
          "Iodine Value (Wijs)",
          "M.Pt (AOCS Cc 3-25)",
          "Colour (5.25″ Lovibond cell)",
        ],
        quality_guide_line: [
          "0.20% max",
          "0.15% max",
          "48 max",
          "44 Deg C min",
          "3 Red max",
        ],
      },
      {
        product: "Palm Fatty Acid Distillate",
        item: ["Saponifiable Fatty Matter", "M & I", "FFA (as palmitic)"],
        quality_guide_line: ["95% min. (basis 97%)", "1.00% max", "70% min"],
      },
      {
        product: "Crude Palm Olein",
        item: [
          "FFA (as palmitic)",
          "M & I",
          "Iodine Value (Wijs)",
          "M.Pt (AOCS Cc 3-25)",
        ],
        quality_guide_line: ["5% max", "0.25% max", "56 min", "24 Deg C max"],
      },
      {
        product: "Crude Palm Stearin",
        item: [
          "FFA (as palmitic)",
          "M & I",
          "Iodine Value (Wijs)",
          "M.Pt (AOCS Cc 3-25)",
        ],
        quality_guide_line: ["5% max", "0.25% max", "48 max", "44 Deg C min"],
      },
    ],
  },

  {
    name: "MEOMA",
    details: [
      {
        product: "RBD Palm Kernel Oil",
        item: [
          "FFA (as lauric acid)",
          "M & I",
          "Iodine Value (Wijs)",
          "Colour (5.25″ Lovibond cell)",
        ],
        quality_guide_line: [
          "0.10% max",
          "0.10% max",
          "19 max at time of shipment",
          "Red 1.5 max",
        ],
      },
      {
        product: "Crude Palm Kernel Oil",
        item: ["FFA (as lauric acid)", "M & I", "Iodine Value (Wijs)"],
        quality_guide_line: [
          "5.00% max",
          "0.50% max",
          "19 max at time of shipment",
        ],
      },
      {
        product: "Crude Palm Kernel Olein",
        item: ["FFA (as lauric acid)", "M & I", "Iodine Value (Wijs)"],
        quality_guide_line: ["5.00% max", "0.50% max", "21 min"],
      },
      {
        product: "Crude Palm Kernel Stearin",
        item: ["FFA (as lauric acid)", "M & I", "Iodine Value (Wijs)"],
        quality_guide_line: ["5.00% max", "0.50% max", "8 max"],
      },
      {
        product: "Palm Kernel Fatty Acid Distillate",
        item: ["FFA (as lauric acid)", "M & I", "TFM"],
        quality_guide_line: ["50% min", "1.00% max", "95% min"],
      },
      {
        product: "RBD Coconut Oil",
        item: [
          "FFA (as lauric acid)",
          "M & I",
          "Iodine Value (Wijs)",
          "Colour (5.25″ Lovibond cell)",
        ],
        quality_guide_line: [
          "0.10% max",
          "0.10% max",
          "7.5 - 10.5",
          "Red 1.5 max",
        ],
      },
      {
        product: "Crude Coconut Oil (Grade 1)",
        item: ["FFA (as lauric acid)", "M & I", "Iodine Value (Wijs)"],
        quality_guide_line: ["1.00% max", "0.50% max", "7.5 - 10.5"],
      },
    ],
  },
];

export const productDetailsData = [
  {
    title: "RBD PALM OLEIN",
    description:
      "Refined, Bleached & Deodorised Palm Olein (RBD Palm Olein) is the liquid fraction obtained from fractionation of palm oil. RBD Palm Olein is fully liquid at ambient temperature in warm climates. It can blend with various vegetable oils in different proportions to obtain liquid oils which can withstand lower temperatures. It is used as cooking oil for household and in food manufacturing such as fried food products. Our brands White Lily, WOK, Marigold and Aster are popular in several destinations around the world.",
    pictures: [
      "white-lily",
      "wok-brand",
      "marigold",
      "aster-brand-purple",
      "oleinNew2",
      "wokconsumer",
      "packedOlein1ss43",
      "packedOlein2ss43",
    ],
    parent_route: "packed",
    product_specification: [
      {
        IV: "56 min",
        FFA: "0.1% max",
        MNI: "0.1% max",
        CL: "3.0R max",
        CP: "10°C max",
      },
      {
        IV: "57 min",
        FFA: "0.1% max",
        MNI: "0.1% max",
        CL: "3.0R max",
        CP: "8°C max",
      },
      {
        IV: "58 min",
        FFA: "0.1% max",
        MNI: "0.1% max",
        CL: "3.0R max",
        CP: "7°C max",
      },
      {
        IV: "60 min",
        FFA: "0.1% max",
        MNI: "0.1% max",
        CL: "3.0R max",
        CP: "6°C max",
      },
      {
        IV: "62 min",
        FFA: "0.1% max",
        MNI: "0.1% max",
        CL: "3.0R max",
        CP: "6°C max",
      },
      {
        IV: "64 min",
        FFA: "0.1% max",
        MNI: "0.1% max",
        CL: "3.0R max",
        CP: "6°C max",
      },
      {
        IV: "66 min",
        FFA: "0.1% max",
        MNI: "0.1% max",
        CL: "3.0R max",
        CP: "6°C max",
      },
    ],
  },
  {
    title: "RBD PALM OIL",
    description:
      "Refined, Bleached & Deodorised Palm Oil (RBD Palm Oil) is obtained from refining Crude Palm Oil (CPO). It is bland, odorless, light yellow in colour and semi-solid in room temperature, making it ideal for use as deep frying oil for food industries such as snack products and baked goods. It is also used as a raw material to produce shortening, margarine, vegetable ghee, condensed milk and soap.",
    pictures: [
      "packedPalm1ss43",
      "packedPalm2ss43",
      "packedPalm3ss43",
      "packedPalm4ss43",
    ],
    parent_route: "packed",
    product_specification: [
      {
        IV: "50-55",
        FFA: "0.1% max",
        MNI: "0.1% max",
        CL: "3.0R max",
        SMP: "30-39°C max",
      },
    ],
  },
  {
    title: "RBD PALM STEARIN",
    description:
      "Refined, Bleached & Deodorised Palm Stearin (RBD Palm Stearin) is the solid fraction from the fractionation of palm oil. It can blend with other vegetable oils to obtain suitable functional products such as margarine fats, shortenings, vegetable ghee etc. It is also a useful natural hard stock for making trans-free fats and used as raw material in feed, soap, candles and oleochemical industries.",
    pictures: ["stearin1ss43", "stearin2ss43", "stearin3ss", "stearin4ss43"],
    parent_route: "packed",
    product_specification: [
      {
        IV: "48 max",
        FFA: "0.2% max",
        MNI: "0.15% max",
        CL: "3.0R max",
      },
    ],
  },

  {
    title: "VEGETABLE GHEE",
    description:
      "Vegetable Ghee also known as Vanaspati, is made up almost entirely of hardened vegetable oil and is free from any milk products. It is a vegetarian alternative to this product. It is ideal for various forms of cooking, baking and frying.",
    pictures: ["ghee1ss43", "ghee2ss43", "ghee3ss43", "ghee4ss43"],
    parent_route: "packed",
    product_specification: [
      { SMP: "36-39°C", FFA: "0.1% max", MNI: "0.1% max", CL: "5.0R max" },
      { SMP: "40-42°C", FFA: "0.1% max", MNI: "0.1% max", CL: "5.0R max" },
    ],
  },
  {
    title: "SHORTENING",
    description:
      "Shortening is a type of solid fat made from palm oil with some of the unsaturated fats removed so that it is even more stable and has a thick, luxurious texture that is pefect for baking. It is widely used for making pastry and cooking.",
    pictures: [
      "shortening1ss43",
      "shortening2ss",
      "shortening3ss43",
      "shortening4ss43",
    ],
    parent_route: "packed",
    product_specification: [
      { SMP: "34-38°C", FFA: "0.1% max", MNI: "0.1% max", CL: "5.0R max" },
      { SMP: "36-39°C", FFA: "0.1% max", MNI: "0.1% max", CL: "5.0R max" },
      { SMP: "38-41°C", FFA: "0.1% max", MNI: "0.1% max", CL: "5.0R max" },
      { SMP: "41-43°C", FFA: "0.1% max", MNI: "0.1% max", CL: "5.0R max" },
      { SMP: "42-44°C", FFA: "0.1% max", MNI: "0.1% max", CL: "5.0R max" },
      { SMP: "44-48°C", FFA: "0.1% max", MNI: "0.1% max", CL: "5.0R max" },
      { SMP: "48-50°C", FFA: "0.1% max", MNI: "0.1% max", CL: "5.0R max" },
      { SMP: "48-52°C", FFA: "0.1% max", MNI: "0.1% max", CL: "5.0R max" },
      { SMP: "50-52°C", FFA: "0.1% max", MNI: "0.1% max", CL: "5.0R max" },
    ],
  },

  {
    title: "FRYING FAT",
    description:
      "Frying Fat is made from palm oil. It is highly heat stable and naturally resistant to oxidation. It is specially designed for all purpose deep frying and excellent for multiple frying application in snack food industries and fast-food outlets.",
    pictures: [
      "fryingFat1ss43",
      "fryingFat2ss43",
      "fryingFat3ss43",
      "fryingFat4ss43",
    ],
    parent_route: "packed",
    product_specification: [
      {
        P: "Super Fry",
        SMP: "39°C max",
        FFA: "0.1% max",
        MNI: "0.1% max",
        CL: "5.0R max",
      },
      {
        P: "Xtra Fry",
        SMP: "42°C max",
        FFA: "0.1% max",
        MNI: "0.1% max",
        CL: "5.0R max",
      },
    ],
  },
  {
    title: "BUTTER OIL SUBSTITUTE",
    description:
      "Butter Oil Substitute (BOS) is a vegetarian alternative to butter oil. It is widely used in the bakery industry to replace the dairy-based butter oil. It is similar to shortening. However, BOS have strong butter flavour and deep yellow colour.",
    pictures: [
      "butteroilsubNew3",
      "butteroilsubNew2",
      "butteroilsub3",
      "butteroilsubNew4",
    ],
    parent_route: "packed",
    product_specification: [
      { SMP: "36-39°C", FFA: "0.1% max", MNI: "0.1% max", CL: "7.0R max" },
    ],
  },
  {
    title: "MILK FAT REPLACER",
    description:
      "Milk Fat Replacer is a substitute for milk fat. It is specially formulated vegetable fat blends that can replace milk fat in various dairy products. It is widely used for making cheese, whipping cream and spread.",
    pictures: ["milkfatNew5", "milkfatNew6", "milkfatNew4", "milkfatNew7"],
    parent_route: "packed",
    product_specification: [
      { SMP: "30-35°C", FFA: "0.1% max", MNI: "0.1% max", CL: "5.0R max" },
    ],
  },
  {
    title: "SUGAR",
    description:
      "Sugar is the generic name for sweet-tasting, soluble carbohydrates, many of which are used in food. Simple sugars, also called monosaccharides, include glucose, fructose, and galactose. Compound sugars, also called disaccharides or double sugars, are molecules made of two bonded monosaccharides; common examples are sucrose (glucose + fructose), lactose (glucose + galactose), and maltose (two molecules of glucose). White sugar is a refined form of sucrose. In the body, compound sugars are hydrolysed into simple sugars.",
    pictures: ["sugar1ss43", "sugar2ss43", "sugar3ss43", "sugar4ss43"],
    parent_route: "packed",
    product_specification: [],
  },
  {
    title: "RICE",
    description:
      "Rice is a staple food in many cultures around the world and has been a part of the human diet for thousands of years. Rice is a versatile grain that can be used in a variety of dishes, and it is a good source of vitamins and minerals. Our organisation offer a wide variety of rice products to choose from. We have rice products for all occasions, from everyday meals to special occasions. We also have a variety of rice products for different dietary needs, including gluten-free and vegan options. We are committed to providing our customers with the highest quality rice products available. We source our rice from the best growers and processors in the world, and we only sell products that we would personally eat ourselves.",
    pictures: ["rice1ss43", "rice2ss43", "rice3ss43", "rice4ss43"],
    parent_route: "packed",
    product_specification: [],
  },
  {
    title: "Split Palm Oil Fatty Acid".toUpperCase(),
    description: "",
    picture_url: "",
    parent_route: "oleochemicals",
    product_specification: [],
  },
  {
    title: "Split Palm Stearin Fatty Acid".toUpperCase(),
    description: "",
    picture_url: "",
    parent_route: "oleochemicals",
    product_specification: [],
  },
  {
    title: "Split Palm Kernel Fatty Acid".toUpperCase(),
    description: "",
    picture_url: "",
    parent_route: "oleochemicals",
    product_specification: [],
  },
  {
    title: "Split Coconut Fatty Acid".toUpperCase(),
    description: "",
    picture_url: "",
    parent_route: "oleochemicals",
    product_specification: [],
  },
  {
    title: "Distilled Palm Oil Fatty Acid".toUpperCase(),
    description: "",
    picture_url: "",
    parent_route: "oleochemicals",
    product_specification: [],
  },
  {
    title: "Distilled Palm Stearin Fatty Acid".toUpperCase(),
    description: "",
    picture_url: "",
    parent_route: "oleochemicals",
    product_specification: [],
  },
  {
    title: "Distilled Palm Kernel Fatty Acid".toUpperCase(),
    description: "",
    picture_url: "",
    parent_route: "oleochemicals",
    product_specification: [],
  },
  {
    title: "Distilled Coconut Fatty Acid".toUpperCase(),
    description: "",
    picture_url: "",
    parent_route: "oleochemicals",
    product_specification: [],
  },
  {
    title: "Caprylic Capric Acid".toUpperCase(),
    description: "",
    picture_url: "",
    parent_route: "oleochemicals",
    product_specification: [],
  },
  {
    title: "Lauric Acid".toUpperCase(),
    description: "",
    picture_url: "",
    parent_route: "oleochemicals",
    product_specification: [],
  },
  {
    title: "Myristirc Acid".toUpperCase(),
    description: "",
    picture_url: "",
    parent_route: "oleochemicals",
    product_specification: [],
  },
  {
    title: "Palmitic Acid".toUpperCase(),
    description: "",
    picture_url: "",
    parent_route: "oleochemicals",
    product_specification: [],
  },
  {
    title: "Stearic Acid".toUpperCase(),
    description: "",
    picture_url: "",
    parent_route: "oleochemicals",
    product_specification: [],
  },
  {
    title: "Oleic Acid".toUpperCase(),
    description: "",
    picture_url: "",
    parent_route: "oleochemicals",
    product_specification: [],
  },
  {
    title: "Hydrogenated Palm Oil".toUpperCase(),
    description: "",
    picture_url: "",
    parent_route: "oleochemicals",
    product_specification: [],
  },
  {
    title: "Hydrogenated Palm Stearin".toUpperCase(),
    description: "",
    picture_url: "",
    parent_route: "oleochemicals",
    product_specification: [],
  },
  {
    title: "Toiletries Soap Noodles".toUpperCase(),
    description: "",
    picture_url: "",
    parent_route: "oleochemicals",
    product_specification: [],
  },
  {
    title: "Laundry Soap Noodles".toUpperCase(),
    description: "",
    picture_url: "",
    parent_route: "oleochemicals",
    product_specification: [],
  },
  {
    title: "Multipurpose Soap Noodles".toUpperCase(),
    description: "",
    picture_url: "",
    parent_route: "oleochemicals",
    product_specification: [],
  },
  {
    title: "Crude Glycerin".toUpperCase(),
    description: "",
    picture_url: "",
    parent_route: "oleochemicals",
    product_specification: [],
  },
  {
    title: "Refined Glycerin".toUpperCase(),
    description: "",
    picture_url: "",
    parent_route: "oleochemicals",
    product_specification: [],
  },
];

import { Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container className="pt-5 mt-2">
      <Row className="mb-4">
        <Typography
          textAlign="left"
          variant="h3"
          sx={{
            fontWeight: "300 !important",
          }}
        >
          About Us
        </Typography>
      </Row>
      {/* <Row className="m-2">
        <div className="col-12 col-md-6 text-end">
          <Typography variant="h3">
            About <span style={{ color: "#ab2f2b" }}>KPN Corp</span>
          </Typography>
          <div></div>
          <p className="text-start">
            KPN Corp consists of four main business divisions, namely :
            agriculture, cement and building materials, property, and
            international trading. Primarily based in Indonesia, we have also
            business presence in Singapore, Vietnam, Sri Lanka, and Mauritius.
            We are committed to providing the best quality products and services
            to our customers, and have more than 30,000 employees that serve the
            world in a responsible way. Together with our staff, customers,
            governments and communities, we strive to minimize environmental
            impact and improve the communities where we live and work.
          </p>
        </div>
        <div className="col-12 col-md-6 d-flex align-items-center">
          <img src="images/kpn-logo.png" className="img-fluid" alt="" />
        </div>
      </Row> */}
      <Row className="my-2 p-1">
        <div className="col-12 col-lg-6 d-flex align-items-center">
          <img src="images/aboutss43.jpg" alt="" className="img-fluid" />
        </div>
        <div className="col-12 col-lg-6 text-start align-items-center d-flex flex-column justify-content-start">
          <div className="w-100">
            <Typography variant="h4" sx={{ color: "#ab2f2b" }}>
              Green Resources Pte Ltd
            </Typography>
          </div>
          <p>
            <br/>At Green Resources, we are committed to revolutionizing the sustainable energy and environmental solutions industry.
            <br/>With a profound dedication to eco-conscious innovation, we have emerged as a frontrunner in the production and distribution of vital bioenergy resources, including ISCC/NIS certified Palm Oil Mill Effluent (POME), Used Cooking Oil (UCO), Palm Methyl Ester (PME), and a range of other cutting-edge products.
          </p>

          <p>
            <br/><h5 style={{textDecoration: "underline"}}>Our Vision</h5>
            Our vision is to create a greener, more sustainable world by harnessing the potential of renewable resources and transforming them into high-value, environmentally friendly products. We believe in the power of innovation, science, and responsible business practices to drive positive change.
          </p>

          <p>
            <br/><h5 style={{textDecoration: "underline"}}>Our Commitment to Sustainability</h5>
            At Green Resources, sustainability is a way of life. We are dedicated to ethical practices, responsible sourcing, and eco-friendly production methods. Our mission is to make a positive impact on the environment and promote a circular economy that benefits both businesses and the planet.
          </p>
          
          {/* <Typography sx={{ width: "100%" }} variant="h4" align="left">
            Mission
          </Typography>
          <Typography variant="body1" align="left">
            <br/>Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Culpa aliquam impedit quibusdam soluta mollitia dolorem
            recusandae ab iste cumque. Similique explicabo saepe
            perferendis expedita quaerat magni. Harum dolores
            assumenda maiores.
          </Typography> */}
        </div>
      </Row>
      {/* <Row className="mt-3 p-1">
        <Col className="p-3" lg="6" sm="12">
          <Typography variant="h4" align="left">
            Vision
          </Typography>
          <Typography variant="body1" align="left">
            To prosper together with our stakeholders, in a responsible and
            sustainable way.
          </Typography>
        </Col>
        <Col className="p-3" lg="6" sm="12">
          <Typography variant="h4" align="left">
            Mission
          </Typography>
          <Typography variant="body1" align="left">
            To provide the best quality products and services, empowering
            people's lives and communities, whilst ensuring environmental
            sustainability, and in doing so, provide additional value to all
            stakeholders.
          </Typography>
        </Col>
      </Row> */}

      {/* <div className="row m-3">
        <div className="col-3">
          <img
            height="200"
            src="images/cemindo-warna.jpg"
            alt=""
            className="img-fluid"
          />
        </div>
        <div className="col-3">
          <img
            height="200"
            src="images/gama-warna.jpg"
            alt=""
            className="img-fluid"
          />
        </div>
        <div className="col-3">
          <img
            height="200"
            src="images/baru-warna.jpg"
            alt=""
            className="img-fluid"
          />
        </div>
        <div className="col-3">
          <img
            height="200"
            src="images/baruplant-warna.jpg"
            alt=""
            className="img-fluid"
          />
        </div>
      </div> */}
    </Container>
  );
};

export default About;
